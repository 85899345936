<template>
    <el-container class="home-container">
        <!--    导航-->
        <el-header>
            <div style="margin-left:30px; font-size: 25px; color: #001529">
                松下软件市场（工厂端）
            </div>
            <el-menu :default-active="activePath" router class="el-menu-demo" mode="horizontal">
                <el-menu-item index="/softwareView/softwareShop" @click="savePath('/softwareView/softwareShop')">软件商店
                </el-menu-item>
                <el-menu-item index="/applyView/apply" @click="savePath('/applyView/apply')">申请一览</el-menu-item>
                <el-menu-item index="/empoweringView/empowering" @click="savePath('/empoweringView/empowering')">端末一览
                </el-menu-item>

                <el-menu-item index="/ukeyView/ukey" @click="savePath('/ukeyView/ukey')">uKey一览</el-menu-item>
                <el-menu-item index="/environmentView/environment" @click="savePath('/environmentView/environment')">
                    系统设置
                </el-menu-item>


            </el-menu>
            <el-dropdown style="margin-right:0px;">
                <div class="block">
                    <el-avatar :size="50" :src="img" style="cursor: pointer"></el-avatar>
                </div>

                <el-dropdown-menu class="home_dropdown_menu" slot="dropdown" trigger="click">
                    <el-dropdown-item>
                            <span type="danger" @click="toPassword">
                                <span class="el-icon-house"></span> &nbsp;修改密码</span>
                    </el-dropdown-item>
                    <div @click="logout">
                        <el-dropdown-item>
              <span type="danger"
              ><span class="el-icon-switch-button"></span>
                &nbsp;退出登录</span
              >
                        </el-dropdown-item>
                    </div>
                </el-dropdown-menu>
            </el-dropdown>
        </el-header>


        <!--主体-->
        <el-container style="height: 600px">
            <!--右边主体-->
            <el-main>
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
    import MenuTree from "../components/MenuTree"; //引进菜单模板

    export default {
        data() {
            return {
                isCollapse: false,
                isAdmin: false,
                passWordDialog: false,
                loading: true,
                activePath: "", //激活的路径
                isOpen: false,
                formLabelWidth: "125px",
                menuList: {},
                userInfo: {},
                img: require("../assets/131724.png"),
                static: "",
                loginUser: "",
                passWordRules: {
                    authorization: [
                        {required: true, message: "请输入授权码", trigger: "blur"},
                    ],
                },
                passWordMsg: {},
                menuType: false,
                authorization: LocalStorage.get("authorization") == true ? true : false
            };
        },
        components: {
            MenuTree,
        },
        methods: {

            savePath(path) {
                window.sessionStorage.setItem("activePath", path);
                this.activePath = path;
            },
            ctAuthorization() {
                this.$http
                    .post("/system/lyUser/selectAuthorization", this.passWordMsg)
                    .then((successData) => {
                        if (successData.data.static) {
                            this.$message({
                                type: "success",
                                message: "授权成功!",
                            });
                            // this.menuType = true
                            LocalStorage.set("deleteOrderFlg", true);
                            this.passWordDialog = false;
                            localStorage.setItem("menuType", true);
                            window.location.reload();
                            this.getMenuList();
                            LocalStorage.set("authorization", true);
                        } else {
                            this.$message({
                                type: "error",
                                message: "授权码错误!",
                            });
                        }
                    });
            },
            addWindow() {
                this.passWordDialog = true;
            },
            /**
             *
             * 退出登入
             */
            async logout() {
                const res = await this.$confirm("此操作将退出系统, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消退出登入",
                    });
                });
                if (res === "confirm") {
                    LocalStorage.clearAll();
                    sessionStorage.clear();
                    await this.$router.push("/login");
                }
            },
            /**
             * 去修改密码
             */
            toPassword() {
                this.$router.push("/system/password");
            },
            /**
             加载菜单数据
             */
            async getMenuList() {
                const {data: res} = await this.$http.get("system/user/findMenu");
                if (!res.success) {
                    return this.$message.error("获取菜单失败:" + res.msg);
                }
                this.menuList = res.data;
            },
            /**
             获取用户信息
             */
            async getUserInfo() {
                const {data: res} = await this.$http.get("system/user/info");
                if (!res.success) {
                    return this.$message.error("获取用户信息失败:" + res.msg);
                } else {
                    this.userInfo = res.data;
                    // console.log("用户信息：" + JSON.stringify(this.userInfo))
                    this.loginUser = this.userInfo.username;
                    this.$store.commit("setUserInfo", res.data);
                    this.getMenuList();
                    this.activePath = window.sessionStorage.getItem("activePath");
                    setTimeout(() => {
                        this.loading = false;
                    }, 500);
                }
            },
            /**
             * 菜单伸缩
             */
            toggleMenu() {
                this.isOpen = !this.isOpen;
            },
            closePassWordDialog() {
                this.$refs.passWordRef.clearValidate();
                this.passWordMsg = {};
            },
            /**
             * 点击交流
             */
            getContact() {
                const w = window.open("about:blank");
                w.location.href = "https://www.zykcoderman.xyz/";
            },
        },
        mounted() {
            // this.getUserInfo();
        },
        created() {
            this.savePath(!window.sessionStorage.getItem("activePath") ? "/softwareView/softwareShop" : window.sessionStorage.getItem("activePath"))
        },
    };
</script>

<style>
    /* 为对应的路由跳转时设置动画效果 */

    .el-header {
        background-color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        font-size: 19px;
        padding-left: 0px;
        border: 1px solid #DCDFE6;
    }

    .home-container {
        width: 100%;
        height: 100% !important;
    }

    .el-menu-demo {
        margin-right: 40%;

    }

    .el-aside {
        background-color: #FFF;
    }

    .el-main {
        background-color: #FFF;
    }

    .toggle-btn {
        background-color: #2d8cf0 !important;
        font-size: 10px;
        line-height: 24px;
        color: #fff;
        text-align: center;
        letter-spacing: 0.2em;
        cursor: pointer;
    }

    .update-input {
        width: 300px;
    }

    .home_dropdown_menu {
        margin-right: 0px;
    }
</style>
